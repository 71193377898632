import React from 'react'
import s from './emailConfirmation.module.css'
import email from '../../../assets/images/mail.png'
import {Button, Input} from "../../../core";

export const EmailConfirmation = () => {

    return (
        <div className={s.container}>
            <img src={email} alt="email"/>
            <div className={`f32-600`}>Revisa tu correo</div>
            <div className={`f20-500`}>Confirma tu correo electrónico, ingresa el código de verificación que enviamos a
                tú mail
            </div>
            <div className={s.form}>
                <Input placeholder={'Código de Verificación'}/>
                <Button variant={'primary'} label={'Confirmar'}/>
            </div>
            <div className={s.row}>
                <Button label={'Re enviar código'} className={`20_500 ${s.btn}`}/>
                <Button label={'Cambiar correo'} className={`20_500 ${s.btn}`}/>
            </div>
        </div>
    )
}
