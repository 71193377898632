import React, {useEffect} from 'react'
import s from './general.module.css'
import {Outlet, useNavigate} from "react-router-dom";
import {Topbars} from "../../components";
import logo from "../../assets/images/logo.png";
import {Button, Icon} from "../../core";

export const General = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/politics')
    }, [])

    return (
        <div className={s.container}>
            <div className={s.block}>
                <Topbars/>
                <Outlet/>
                <div className={s.footer_container}>
                    <img src={logo} alt="logo" style={{marginRight: 40}}/>
                    <div className={s.footer_text}>Copyright ©, 2023 Company Name, All Rights Reserved</div>
                    <div className={s.footer_btn_container}>
                        <Button>
                            <Icon type={'Twitter'} size={22}/>
                        </Button>
                        <Button>
                            <Icon type={'Facebook'} size={22}/>
                        </Button>
                        <Button>
                            <Icon type={'Youtube'} size={22}/>
                        </Button>
                        <Button>
                            <Icon type={'Linkedin'} size={22}/>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
