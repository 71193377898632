import s from './topbar.module.css'
import logo from '../../assets/images/logo.png'
import {Button} from "../../core";

export const Topbars = ()=>{
    return (
        <div className={s.container}>
            <div className={s.block}>
                <img src={logo} alt="logo" style={{marginRight:40}}/>
                <Button label={'AR4Politics'} className={s.link} link={'/politics'} activeClass={s.active_tab}/>
                <Button label={'¿Qué es AR?'} className={s.link} link={'/pricing'} activeClass={s.active_tab}/>
                <Button label={'Costos'}  className={s.link} link={'/pricing'} activeClass={s.active_tab}/>
            </div>
            <div className={s.link_container}>
                <Button label={'Crear Cuenta'} variant={'primary'} link={'/auth/signup'}/>
                <Button label={'Iniciar sesión'} variant={'secondary'} link={'/auth/signin'}/>
            </div>
        </div>
    )
}
