import React from 'react'
import s from './signin.module.css'

export const Signin = ()=>{

    return (
        <div className={s.container}>
            Signin
        </div>
    )
}
