import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {
    Politics,
    Auth,
    General,
    Signin,
    Signup, EmailConfirmation,
} from './pages'

const App = () => {
    const {pathname} = useLocation()

    useEffect(() => {
        document.title = pathname.split('/').pop().uppercaseFirstChar()
    }, [pathname])

    return (
        <Routes>

            <Route path="/" element={<General/>}>
                <Route index path="politics" element={<Politics/>}/>
                <Route path="/auth" element={<Auth/>}>
                    <Route index path="signin" element={<Signin/>}/>
                    <Route path="signup" element={<Signup/>}/>
                    <Route path="email-confirmation" element={<EmailConfirmation/>}/>
                </Route>
                <Route path="/*" element={<Navigate to="/politics" replace/>}/>
            </Route>
        </Routes>
    );
}

export default App;
