import React, {useCallback, useState} from 'react'
import s from './signup.module.css'
import {Button, Checkbox, Icon, Input, UploadFile} from '../../../core'
import {Password} from "../../../components";
import {onChangeBody} from "../../../resources";
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useNavigate} from "react-router-dom";

export const Signup = () => {
    const [body, setBody] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    // const {executeRecaptcha} = useGoogleReCaptcha();
    const navigate = useNavigate()

    const onSubmit = useCallback(
        async (e) => {
            // try {
            //
            // } catch (error) {
            //     console.log("error ", error);
            // }
            // e.preventDefault();
            // if (!executeRecaptcha) {
            //     console.log("Execute recaptcha not yet available");
            //     return;
            // }
            // executeRecaptcha("enquiryFormSubmit").then((gReCaptchaToken) => {
            //     console.log(gReCaptchaToken, "response Google reCaptcha server");
            navigate('/auth/email-confirmation')
            // });
        },
        []
    );

    const onChange = (e, _, _isValid) => {
        onChangeBody(e, body, setBody, _isValid)
    }

    const onSelectList = (e) => {
       setBody(prev=>({...prev,plan:e.value}))
    }

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey="6LeYZTInAAAAANd_tXAMHi3NqHOqjHopRkU4OF5d"
            language="es"
            // useRecaptchaNet="[optional_boolean_value]"
            // useEnterprise="[optional_boolean_value]"
            scriptProps={{
                async: false,
                defer: false,
                appendTo: 'head',
                nonce: undefined
            }}
            container={{
                element: "recaptch-v3",
                parameters: {
                    // badge: '[inline|bottomright|bottomleft]', // optional, default undefined
                    badge: 'inline',
                    theme: 'light', // optional, default undefined
                }
            }}
        >
            <div className={`f20-500 ${s.title}`}>Datos de Contacto</div>
            <div className={s.body}>
                <Input placeholder={'Nombres y apellidos del Candidato'}/>
                <Input placeholder={'Número de teléfono de Contacto'}/>
                <Input placeholder={'Correo electrónico de Contacto'}/>
                <Input placeholder={'Nombre del Partido o movimiento político del Candidato'}/>
                <Input placeholder={'Código DANE del municipio'}/>
                <Input placeholder={'Categoría del municipio'}/>
                <Input placeholder={'Nombre del municipio'}/>
                <Input placeholder={'Category of municipality'}/>
            </div>

            <Password onChange={onChange}
                      password={body['password']}
                      confirmPassword={body['confirmPassword']}
            />

            <div className={`f20-500 ${s.title}`}>Insumos para prepar tu escena de Realidad Aumentada</div>

            <div className={s.row}>
                <UploadFile className={s.upload_container}>
                    <Icon type={'Picture'} size={50}/>
                    <div>
                        Arrastre la foto del candidato con la ropa representativa de la campaña o <span>Buscarlo</span>
                    </div>
                    <small>Max 2mb </small>
                </UploadFile>
                <UploadFile className={s.upload_container}>
                    <Icon type={'Picture'} size={50}/>
                    <div>
                        Arrastre el logo del candidato o <span>Buscarlo</span>
                    </div>
                    <small>Max 2mb </small>
                </UploadFile>
                <UploadFile className={s.upload_container}>
                    <Icon type={'Audio'} size={50}/>
                    <div>
                        Arrastre el audio de la propuesta o <span>Buscarlo</span>
                    </div>
                    <small>Max 2mb </small>
                </UploadFile>
            </div>
            <div className={`f20-500 ${s.title}`}>Link del sitio oficial del candidato</div>
            <div className={s.body}>
                <Input placeholder={'Category of municipality'}/>
            </div>

            <div className={`f20-500 ${s.title}`}>Selecciona el plan de tu interés, nuestro equipo de ventas se pondrá
                en contacto contigo
            </div>
            <div className={`${s.block}`}>
                <div className={`${s.list} ${body?.plan === 'full' ? s.selected_plan: null}`}
                     onClick={() => onSelectList({value: 'full'})}>Full Campaña ($10.499.999/mes) paga los 3 meses de
                    campaña y ahorra 25%
                </div>
                <div className={`${s.list} ${body?.plan === 'half' ? s.selected_plan: null}`}
                     onClick={() => onSelectList({value: 'half'})}>Media Campaña ($12.599.999/mes) paga 2 meses y ahorra
                    10%
                </div>
                <div className={`${s.list} ${body?.plan === 'small' ? s.selected_plan: null}`}
                     onClick={() => onSelectList({value: 'small'})}>Small Campaña ($13.999.999/mes) paga 1 mes
                </div>
                <div className={`${s.list} ${body?.plan === 'free' ? s.selected_plan: null}`}
                     onClick={() => onSelectList({value: 'free'})}
                >
                    Versión Gratuita / 15 días de prueba por una única vez
                </div>
                <div className={`${s.card} ${s.green_block}`}>
                    <div className={s.mark}><Icon type={'ExclamationMark'} size={56}/></div>
                    <div className={``}>¡Excelentes noticias para ti! <b>Todos</b> nuestros planes te ofrecen <b>15 días
                        de prueba
                        gratuita</b>, pero si optas por nuestros planes Full, Media y Small, garantizarás todos los <b>beneficios
                        Premium</b>. No pierdas más tiempo y asegura tu cupo en tu municipio. ¡No dejes pasar esta
                        oportunidad, separa el tuyo ahora mismo!
                    </div>
                </div>
            </div>

            <div className={`f20-500 ${s.title}`}>
                Selecciona el plan de tu interés, nuestro equipo de ventas se pondrá en contacto contigo
            </div>

            <div className={`${s.card} ${s.brown_block}`}>
                <div className={s.mark}><Icon type={'ExclamationMark'} size={56}/></div>
                <div className={`${s.brown_header}`}>
                    La versión gratuita por 15 días es por <b>una única vez,</b> pero ten en cuenta que:
                </div>
                <ul>
                    <li>
                        Si eliges la <b>versión gratuita</b> de nuestra plataforma y si otro candidato de tu municipio
                        decide
                        adquirir una versión Premium (para el mismo cargo de elección popular), tu cuenta <b>se
                        deshabilitará automáticamente.</b>
                    </li>
                    <li>
                        La <b>construcción y animación del Avatar</b> pueden requerir <b>hasta 5 días</b>, dependiendo
                        de la demanda
                        que tengamos en ese momento, por lo que te recomendamos que <b>no pierdas tiempo</b> e inicies
                        con un
                        plan premium.
                    </li>
                </ul>
            </div>

            <div className={s.privacy_policy_container}>
                <Checkbox
                    label={'Estoy de acuerdo con los Términos y Condiciones y confirmo que he leído la Política de privacidad.'}
                    className={s.checkbox}
                />
            </div>
            <div className={s.text}>
                Por favor marque la casilla para continuar
            </div>
            <div id={'recaptch-v3'}/>

            <Button label={'Siguiente'}
                    variant={'primary'}
                    className={s.submit_btn}
                    link={'/auth/email-confirmation'}
                    onClick={onSubmit}
            />
        </GoogleReCaptchaProvider>
    )
}
