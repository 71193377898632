import React from 'react'
import s from './auth.module.css'
import {Outlet} from "react-router-dom";
import {Steper} from "../../components";

export const Auth = ()=>{

    return (
        <div className={s.container}>
            <Steper/>
            <Outlet/>
        </div>
    )
}
