import React, { useRef, useLayoutEffect } from "react";
import s from "./modal.module.css";
import { Button, Icon, Portal } from "../index";

const root = document.documentElement;

window.addEventListener("click", (e) => {
    const target = e.target.closest(".modal-handler");
    if (target) {
        root.style.setProperty("--mouse-x", e.pageX + "px");
        root.style.setProperty("--mouse-y", e.pageY + "px");
    }
});

let timer;

export const Modal = ({
    visibility,
    setVisibility,
    children,
    className,
    closeBtnEnabled = true,
    animationTiming = 360,
}) => {
    const modal = useRef();

    useLayoutEffect(() => {
        clearTimeout(timer);
    }, []);

    const onClose = (e) => {
        if (modal.current) {
            modal.current.classList.remove(s["modal_visible"]);
            modal.current.classList.add(s["modal_hidden"]);
            timer = setTimeout(() => {
                setVisibility(false);
            }, animationTiming);
        }
        if (e) {
            e.stopPropagation();
        }
    };

    const childrenWithProps = React.Children.map(children, (child) => {
        const _props = {};
        if (onClose &&  typeof  child.type !== 'string') {
            _props.onCloseModal = onClose;
        }
        if (React.isValidElement(child))  return React.cloneElement(child, _props);

        return child;
    });

    if (visibility) {
        return (
            <Portal>
                <div
                    onClick={onClose}
                    className={[
                        s.container,
                        className,
                    ].join(" ")}
                >
                    <div
                        ref={modal}
                        onClick={(e) => e.stopPropagation()}
                        style={{ animationDuration: animationTiming + "ms" }}
                        className={[
                            s.modal,
                            s["modal_" + (visibility ? "visible" : "hidden")],
                        ].join(" ")}
                    >
                        {closeBtnEnabled && (
                            <Button
                                className={s.close_btn}
                                onClick={onClose}
                                label={
                                    <Icon type={"X"} size={22} feather={true} />
                                }
                            />
                        )}
                        {childrenWithProps}
                    </div>
                </div>
            </Portal>
        );
    }
    return null;
};
