import React from 'react'
import s from './password.module.css'
import {Icon, Input} from "../../core";
import {onChangeBody} from "../../resources";

export const Password = ({
                             onChange,
                             password,
                             confirmPassword,
                         }) => {
    const validations = {
        '1 letra mayúscula': new RegExp('^(?=.*[a-z])'),
        '1 letra minúscula': new RegExp('^(?=.*[A-Z])'),
        'Almenos 1 número': new RegExp('^(?=.*\\d)'),
        'Mínimo 8 caracteres': new RegExp('^[A-Za-z\\d@$!%*?&]{8,}$'),
        // 'special': new RegExp('^(?=.*\\d)(?=.*[@$!%*?&])'),
    }

    return (
        <div className={s.container}>
            <div className={`f20-500 ${s.title}`}>
                Datos de configuración de cuenta
            </div>
            <div className={s.row}>
                <Input placeholder={'Contraseña'}
                       onFinish={onChange}
                       value={password}
                       name={'password'}
                       // validationKey={'password'}
                       // errorMassage={''}
                />
                <Input placeholder={'Reconfirmar Contraseña'}
                       onFinish={onChange}
                       // validationKey={'password'}
                       // errorMassage={''}
                       value={confirmPassword}
                       name={'confirmPassword'}
                />
            </div>
            <div className={`f20-500 ${s.title}`}>Política de contraseñas</div>
            <div className={s.row}>
                <div>{Object.entries(validations).map(([key,value])=>{
                    return <List key={key} label={key} isValid={password === undefined ? false :  value.test(password)}/>
                })}</div>
                <div>
                    <List  label={'Las contraseñas no coinciden'}
                           isValid={password &&  password === confirmPassword}
                    />
                </div>
            </div>
        </div>
    )
}

const List = ({label,isValid})=>{

    return (
        <div className={s.list_container} style={{
            color:isValid ? 'var(--dark-blue)' : 'var(--red)'
        }}>
            <Icon type={isValid ? 'CheckedGreen' : 'CloseRed'} />
            {label}
        </div>
    )
}

