import React from 'react'
import s from './minLoader.module.css'

export const MinLoader = ()=>{
    return (
        <div className={s.container}>
        <div className={s["lds-ring"]}>
            <div></div><div></div><div></div><div></div>
        </div>
         </div>
    )
}
